<template>
  <div class="clubs-page z-flex z-flex-between">
    <div class="clubs-page-left">
      <div class="box clubs-list">
        <div class="hd">{{province}} 学习专区列表</div>
        <div class="bd">
          <div class="list" v-if="clubsList.length>0">
            <div class="item z-flex" v-for="(item,index) in clubsList" :key="index">
              <div class="img-wrap">
                <img class="img" :src="item.img"/>
              </div>
              <div class="text z-flex z-flex-col">
                <div class="title">{{item.title}}</div>
                <div class="desc">{{item.desc}}</div>
              </div>
            </div>
          </div>
          <el-empty description="暂无数据" v-else></el-empty>
        </div>
      </div>
      <div class="box clubs-list my-clubs">
        <div class="hd">我的学习专区</div>
        <div class="bd">
          <div class="list" v-if="myClubsList.length>0">
            <div class="item z-flex" v-for="(item,index) in myClubsList" :key="index" @click="toActivity(item.id)">
              <div class="img-wrap">
                <img class="img" :src="item.img"/>
              </div>
              <div class="text z-flex z-flex-col">
                <div class="title">{{item.title}}</div>
                <div class="desc deadline">到期时间：{{item.deadline}}</div>
              </div>
            </div>
          </div>
          <el-empty description="暂无数据" v-else></el-empty>
        </div>
      </div>
    </div>
    <div class="chart-box" hidden-xs-only>
      <div id="myChart" ref="myChart"></div>
      <div class="visual-tips">
        <div class="item z-flex">
          <div class="label label-1"></div>
          <div class="text">有学习专区入驻</div>
        </div>
        <div class="item z-flex">
          <div class="label label-2"></div>
          <div class="text">无学习专区入驻</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import recommend from "../components/recommend";
// import CHINA from '../../node_modules/echarts/map/json/china.json';
import CHINA from '@/assets/json/china.json';
export default {
  components: {
    recommend,
  },
  data() {
    return {
      mapData:CHINA,
      province:'福建省',
      //学习专区列表
      clubsList:[],
      //我的学习专区列表
      myClubsList:[],
      //学习专区
      specialAreas:[],
    };
  },
  mounted() {
    this.$axios.api.zqMap({}, (res) => {
        if (res.code == 200) {
          this.specialAreas = res.data;
        } else {
          console.log(res.msg);
        }
        this.initMycharts();
    });
    this.$axios.api.myZq({}, (res) => {
        if (res.code == 200) {
          this.myClubsList = res.data;
        } else {
          console.log(res.msg);
        }
    });
    this.zqMap(this.province);
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style','overflow:hidden');
  },
  beforeDestroy() {
    document.querySelector('body').setAttribute('style','overflow:auto');
  },
  created() {

  },
  methods: {
    zqMap(area){
      this.$axios.api.zqList({area: area}, (res) => {
          if (res.code == 200) {
            this.clubsList = res.data;
          } else {
            console.log(res.msg);
          }
      });
    },
    initMycharts() {
      this.$echarts.registerMap('china', this.mapData);
      let myChart = this.$echarts.init(document.getElementById('myChart'));
      myChart.showLoading();
      let option = {
        title: {
          text: '大扬学院学习专区会员',
          left: 'center',
          padding:30,
          textStyle: {
            color: '#fff',
            fontSize:25,
          }
        },
        geo: {
          map: "china",
          zoom: 1, //默认视角的缩放比例
          roam: true, //是否开启平移和缩放，可单独开启
          scaleLimit: {//滚轮缩放的极限控制
            min: 1,
            max: 18,
          },
          itemStyle: {//地图区域样式
            areaColor: '#004981',
            borderColor: '#0381b6',  
            borderWidth:1,
            shadowColor: 'rgba(0, 0, 0, 0)',
            shadowBlur: 10,
          },
          emphasis: {//高亮状态下地图区域样式
            itemStyle: {
              areaColor: '#0069ab',
            },
            label: {// 标签显示样式
              color: '#fff',
            },
          },
          label: {// 标签显示样式
            normal: { 
              show: true, 
              color: '#fff',
              // textBorderWidth:1,
              // textBorderColor:'#fff',
            },  
          },
        },
        visualMap:{
          min: 0,
          max: 1,
          inRange: {
            color: ['#004981', '#ffa400']
          },
          show:false,
        },
        series: [
          {
            type: 'map',
            geoIndex: 0, //指定geo组件
            data:this.specialAreas,//学习专区
          },
        ],
      };
      // 绘制地图
      myChart.setOption(option,true);
      setTimeout(() => {
        myChart.hideLoading();
      }, 500);
      //点击事件
      myChart.on('click', (params) => {
        // console.log('params',params);
        this.province = params.name; //省份中文名
        this.zqMap(this.province);
      });
    },
    toActivity(id){
      this.$router.push({
        path:'/activities',
        query: {
          'id' : id,
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.clubs-page{
  width:calc(100% - 20px);
  height:calc(100vh - 100px);
  padding: 10px;
}
.chart-box{
  width: calc(100% - 480px);
  height: calc(100% - 20px);
  background:#fff;
  padding: 10px;
  border-radius: 4px;
  position: relative;
  #myChart {
    width: 100%;
    height: 100%;
    background:#112d4e;
  }
  .visual-tips{
    position:absolute;
    left:40px;
    bottom:30px;
    color: #fff;
    font-size: 14px;
    z-index: 99;
    .item{
      margin:20px 0;
      .label{
        width: 25px;
        height:12px;
        display: inline-block;
        border:1px solid #3baedf;
        margin-right: 10px;
        &.label-1{
          background-color: #ffa400;
        }
        &.label-2{
          background-color: #004981;
        }
      }
    }
  }
}
.clubs-page-left{
  width:450px;
  height:100%;
  .box{
    width:100%;
    background:#fff;
    border-radius: 4px;
    .hd{
      height:50px;
      line-height: 50px;
      font-size: 20px;
      padding:0 10px;
      border-bottom: 1px solid #eee;
    }
    .bd{
      padding:0 10px;
    }
  }
  .clubs-list{
    height:50%;
    .bd{
      height:calc(100% - 51px);
      overflow-y: auto;
    }
    .item{
      padding:10px 0;
      border-bottom: $borderDotted;
      color:#666;
      .img-wrap{
        width:150px;
        height:100px;
        background:#f5f5f5;
        border-radius: 4;
        overflow: hidden;
      }
      .img{
        width:100%;
        height:100%;
        object-fit:cover;
        border-radius: 4px;
      }
      .text{
        width:calc(100% - 160px);
        height: 100px;
        margin-left: 10px;
        align-items:flex-start;
        flex-wrap: wrap;
        .title{
          @include textOverflowNum(2);
        }
        .desc{
          font-size: 14px;
          color:#999;
          margin-top: 10px;
          @include textOverflowNum(2);
        }
      }
    }
  }
  .my-clubs{
    height:calc(50% - 10px);
    margin-top: 10px;
    .bd{
      height:calc(100% - 51px);
      overflow-y: auto;
    }
    .item{
      cursor: pointer;
      &:hover{
        .title{
          color:$colorMain;
        }
      }
    }
    // .item{
    //   width:calc(50% - 5px);
    //   height:140px;
    //   position: relative;
    //   display:inline-block;
    //   margin:10px 10px 0 0;
    //   border-radius: 4px;
    //   overflow:hidden;
    //   background:#000;
    //   cursor: pointer;
    //   &:nth-child(2n){
    //     margin-right: 0;
    //   }
    //   .img{
    //     width:100%;
    //     height:100%;
    //     object-fit:cover;
    //     opacity: .7;
    //   }
    //   .title{
    //     width:calc(100% - 20px);
    //     position: absolute;
    //     left: 10px;
    //     top: 10px;
    //     color:#fff;
    //   }
    //   .deadline{
    //     width:calc(100% - 20px);
    //     position: absolute;
    //     right: 10px;
    //     bottom: 10px;
    //     color:#fff;
    //     font-size: 14px;
    //     text-align: right;
    //   }
    // }
  }
}
</style>
